import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appDisablePaste]'
})
export class DisablePasteDirective {
  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
  }

  @HostListener('keydown.control.v', ['$event'])
  onCtrlV(event: KeyboardEvent) {
    event.preventDefault();
  }
}