import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { parse } from 'marked';

@Directive({
    selector: '[appMarkedown]'
})
export class MarkedownDirective implements OnInit {

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2) { }

  ngOnInit() {
    const changes$ = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation: MutationRecord) => {
        if (mutation.type === 'characterData') {
          const markText = this.elementRef.nativeElement.innerText;
          if (markText && markText.length > 0) {
            const markdownHtml = parse(markText, { breaks: true });
            this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', markdownHtml);
          }
        }
      });
    });

    changes$.observe(this.elementRef.nativeElement, {
      attributeOldValue: true,
      subtree: true,
      characterData: true,
      characterDataOldValue: true,
    });
  }
}