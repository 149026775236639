import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DisablePasteDirective } from './disablePaste.directive';
import { LongPressDirective } from './long-press.directive';
import { MarkedownDirective } from './markdown.directive';
import { PlaceholderDirective } from './placeholder.directive';

const components = [
  PlaceholderDirective,
  LongPressDirective,
  MarkedownDirective,
  DisablePasteDirective
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule
  ],
  exports: components
})
export class SharedDirectivesModule { }
